.SocialEvents {
  width: 70px;
  position: fixed;
  top: 380px;
  z-index: 9999999;
  right: 0;

  .event {
    width: 100%;
    height: 50px;
    background: #cea0ba;
    margin-bottom: 1px;
    transition: 0.3s;
    opacity: 0.8;
    cursor: pointer;

    &:hover {
      background: transparent;
      i {
        background: #cea0ba;
      }
    }
  }
}
