.SliderContent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(54 38 64 / 74%) 0% 0% no-repeat padding-box;
  z-index: 999999;

  .slider {
    position: fixed;
    width: 1119px;
    height: 808px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    border: 15px solid white;
    
    .close-btn {
      position: absolute;
      display: inline-block;
      top: -56px;
      right: -14px;
      color: white;
      font-size: 35px;
      text-align: center;
      font-size: xx-large;
      cursor: pointer;
    }
  }


  .arrow {
    color: white;
    font-size: 20px;
    position: fixed;
    cursor: pointer;


    &.left-arrow {
      top: 50%;
      left: 100px;
      width: 17px;
      height: 35px;
      transform: translateY(-50%);
    }
    
    &.right-arrow {
      top: 50%;
      transform: translateY(-50%);
      right: 100px;
      width: 17px;
      height: 35px;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}
