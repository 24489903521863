body {
  padding-top: 119px;
    max-width: 100%;
    overflow-x: hidden;
}

.block-image-and-info {
  margin: 144px auto;

  .mini-line {
    width: 132px;
    height: 10px;
  }

  .row {
    align-items: center;

    &>div {
      padding: 0;
    }

    .block-info {
      padding: 65px;
      box-shadow: 0px 3px 45px #00000017;
      width: calc(100% + 115px);
      background: white;
      position: relative;
    }
  }

  .big-title {
    color: var(--unnamed-color-483057);
    font: normal normal bold 48px/60px Cormorant Garamond;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin-bottom: 35px;
  }

  .description {
    color: var(--unnamed-color-969696);
    font: normal normal normal 18px/30px Open Sans;
    letter-spacing: 0.27px;
  }
}
