* {
  padding: 0;
  margin: 0;
}

div {
  line-height: inherit;
}

.container {
  margin: 0 auto;
  position: relative;
}

ul {
  margin: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  &:hover {
    color: inherit;
  }
}

span {
  display: inline-block;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn {
  padding: 17px 51px;
  border: 2px solid #cea0ba;
  font: normal normal normal 12px/12px Open Sans;
  letter-spacing: 1.44px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 0;
  background-color: #cea0ba;

  &.btn-transparent {
    background-color: transparent;
    color: #cea0ba;
  }
}

.mini-line {
  background: #cea0ba;
  width: 132px;
  height: 5px;
  display: block;
  margin-bottom: 20px;
}

i {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.select-box {
  position: relative;

  select {
    appearance: none;
  }

  i {
    font-size: 30px;
    font-family: monospace;
    display: inline-block;
    position: absolute;
    width: 50px;
    right: 0;
    color: #a68da6;
  }
}

.btn-shadow:hover {
  background-color: white;
  color: #483057;
  border-color: white;
  transition: 0.5s;
  box-shadow: inset 130px 0 0 0 white, inset -130px 0 0 0 white;

  &.dark {
    box-shadow: inset 130px 0 0 0 #483057, inset -130px 0 0 0 #483057;
    background-color: #483057;
    color: white;
  }
}
