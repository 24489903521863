:root {
  /* Colors: */
  --unnamed-color-483057: #483057;
  --unnamed-color-3a2647: #3a2647;
  --unnamed-color-969696: #969696;
  --unnamed-color-9677aa: #9677aa;
  --unnamed-color-707070: #707070;
  --unnamed-color-f1f1f1: #f1f1f1;
  --unnamed-color-f4eee8: #f4eee8;
  --unnamed-color-000000: #000000;
  --unnamed-color-60308c: #60308c;
  --unnamed-color-511f73: #511f73;
  --unnamed-color-431259: #431259;
  --unnamed-color-e6f2ff: #e6f2ff;
  --unnamed-color-ffa641: #ffa641;
  --unnamed-color-fd3333: #fd3333;
}
