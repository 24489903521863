.Home {
  .top-image-block {
    position: relative;
    height: 705px;
    padding-top: 139px;

    .container {
      z-index: 2;

      .site-name {
        font: normal normal bold 79px/79px Cormorant Garamond;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        max-width: 594px;
      }

      .welcome {
        font: normal normal normal 30px/45px Open Sans;
        letter-spacing: 0.45px;
        color: #ffffff;
      }
    }

    .layer,
    img {
      background: #3a2647;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &img {
        z-index: 0;
      }

      &.layer {
        z-index: 1;
        opacity: 0.44;
      }
    }
  }

  .event-items {
    margin-top: -203px;
    z-index: 1;
    position: relative;
    overflow: auto;
    width: 100%;

    .d-flex {
      justify-content: center;
      display: flex;
      width: 1320px;
      margin: auto;
    }
    
    .event-item {
      margin-right: 21px;
      
      &:last-child {
        margin-right: 0px;
      }
    }

    .event-box {
      padding: 10px;
      background: white;

      
      .content {
        position: relative;
        width: 406px;
        height: 406px;

        .layer {
          position: absolute;
          height: 100%;
          transition: 0.3s;
          transform: scale(1);
          opacity: 1;
        }

        &:hover {
          .layer {
            transform: scale(0);
            opacity: 0;
          }
        }

        .info {
          color: var(--unnamed-color-707070);
          text-align: center;
          font: normal normal normal 16px/22px Open Sans;
          letter-spacing: 0px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: white;
          height: 100%;
          padding: 103px 50px 64px;
          cursor: pointer;

          .description {
            margin-bottom: 28px;
          }

          .more {
            color: #483057;
          }
        }

        .title {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: #cea0ba;
          opacity: 0.8;
          font: normal normal normal 22px/22px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
          text-transform: uppercase;
          text-align: center;
          padding: 23px 0;
        }
      }
    }
  }

  .description-block {
    color: var(--unnamed-color-969696);
    text-align: center;
    font: normal normal normal 18px/30px Open Sans;
    letter-spacing: 0.27px;
    margin: 140px auto 110px;
  }

  .menu-boxes {
    margin-bottom: 144px;
    width: 100%;
    overflow: auto;

    .d-flex {
      justify-content: center;
      display: flex;
      width: 1320px;
      margin: auto;

      .item {
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }

    .menu-box {
      width: 416px;
      height: 416px;
      border: 1px solid #cea0ba;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      background: linear-gradient(white 50%, var(--unnamed-color-483057) 50%);
      background-size: 100% 200%;
      background-repeat: no-repeat;
      transition: background 0.5s;
      background-position: 100% 200%;

      &.second-box {
        background-position: 100% 0%;
      }

      i {
        width: 101px;
        height: 101px;
        margin-bottom: 20px;
      }

      p {
        color: var(--unnamed-color-969696);
        text-align: center;
        font: normal normal normal 18px/30px Open Sans;
        letter-spacing: 0.27px;
      }
    }

    .menu-box:hover {
      background-position: 100% 100%;
    }
  }

  .start-planning {
    background: var(--unnamed-color-483057) 0% 0% no-repeat padding-box;
    margin-bottom: 144px;

    .row {
      & > div {
        padding: 0;

        &.content {
          padding: 64px 115px 90px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .big-title {
            font: normal normal bold 62px/86px Cormorant Garamond;
            letter-spacing: 0px;
            color: #cea0ba;
            text-transform: uppercase;
          }

          .info {
            font: normal normal normal 18px/30px Open Sans;
            letter-spacing: 0.27px;
            color: #ffffff;
          }

          .phone {
            font: normal normal 600 48px/40px Open Sans;
            letter-spacing: 0px;
            color: #cea0ba;
            text-transform: uppercase;
          }
          .btn {
            display: inline-block;
          }
        }
      }
    }
  }
}
