.Venue {
  .top-image-block {
    position: relative;
    height: 499px;
    padding-top: 139px;

    .mini-line {
      background: #ffffff;
      width: 132px;
      height: 7px;
    }

    .container {
      z-index: 2;

      p {
        font: normal normal normal 30px/45px Open Sans;
        letter-spacing: 0.45px;
        color: #ffffff;
      }

      .site-name {
        font: normal normal bold 79px/90px Cormorant Garamond;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .layer,
    img {
      background: #3a2647;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &img {
        z-index: 0;
      }

      &.layer {
        z-index: 1;
        opacity: 0.44;
      }
    }
  }

  .page-info {
    color: var(--unnamed-color-969696);
    text-align: center;
    font: normal normal normal 20px/30px Open Sans;
    letter-spacing: 0.3px;
    padding: 140px 112px;
    border-radius: 50px;
    margin-top: -80px;
    z-index: 1;
    background-color: white;
  }

  .an-event-venue {
    position: relative;
    text-align: center;
    padding: 71px 0 77px;
    margin-bottom: 122px;

    .layer,
    img {
      background: #3a2647 0% 0% no-repeat padding-box;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &img {
        z-index: 0;
      }

      &.layer {
        z-index: 1;
        opacity: 0.68;
      }
    }

    .circles-row {
      display: flex;
      justify-content: center;
      width: 100%;

      .circles {
        position: relative;
        display: flex;
        z-index: 1;
        margin: auto;
        overflow: auto;
        width: 1353px;
  
        .circle-box {
          width: 238px;
          min-width: 238px;
          height: 238px;
          font: normal normal normal 20px/30px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          margin-right: -15px;
          padding: 0;
          transition: 0.1s;
          position: relative;
  
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 100%;
            padding: 15px;
            background: linear-gradient(
              to bottom,
              rgb(206, 160, 186, 1),
              rgb(206, 160, 186, 0.1)
            );
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
          }
  
          &:hover {
            box-shadow: inset 238px 0 0 0 #cea0ba;
            // background-color: #cea0ba;
            transition: 1s;
          }
        }
      }
    }
    

    .container {
      z-index: 1;

      .mini-line {
        width: 202px;
        height: 5px;
        background: #cea0ba;
        margin: 0 auto 15px;
      }

      h2 {
        font: normal normal bold 48px/40px Cormorant Garamond;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        margin-bottom: 17px;
      }

      p {
        font: normal normal normal 18px/30px Open Sans;
        letter-spacing: 0.27px;
        color: #ffffff;
        margin-bottom: 54px;
      }
    }
  }

  .our-amenities {
    text-align: center;
    color: #483057;
    margin-bottom: 141px;

    .mini-line {
      margin: 0 auto 20px;
    }

    h2 {
      font: normal normal bold 48px/40px Cormorant Garamond;
      letter-spacing: 0px;
      text-transform: uppercase;
    }

    .boxes {
      margin-top: 54px;
      justify-content: space-between;
    }

    .box {
      border: 1px solid #483057;
      display: flex;
      flex-direction: column;
      padding: 34px 0;
      background: linear-gradient(white 50%, var(--unnamed-color-483057) 50%);
      background-size: 100% 200%;
      background-repeat: no-repeat;
      transition: background 0.5s;
      background-position: 100% 200%;
      margin-bottom: 10px;

      &.second-box {
        background-position: 100% 0%;
      }

      &:hover {
        background-position: 100% 100%;
        color: white;
      }

      & > *:not(:last-child) {
        margin-bottom: 15px;
      }

      .num {
        font: normal normal normal 35px/35px Open Sans;
      }

      .title {
        font: normal normal normal 18px/18px Open Sans;
      }

      .icon {
        height: 50px;
      }
    }
  }

  .superior-catering {
    background: var(--unnamed-color-483057) 0% 0% no-repeat padding-box;
    margin-bottom: 144px;

    .row {
      & > div {
        padding: 0;

        &.content {
          padding: 64px 115px 90px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .big-title {
            font: normal normal bold 48px/48px Cormorant Garamond;
            letter-spacing: 0px;
            color: #cea0ba;
            text-transform: uppercase;
          }

          .info {
            font: normal normal normal 18px/30px Open Sans;
            letter-spacing: 0.27px;
            color: #ffffff;
          }

          .phone {
            font: normal normal 600 48px/40px Open Sans;
            letter-spacing: 0px;
            color: #cea0ba;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .enjoy-an-exceptional {
    margin-bottom: 121px;

    .mini-line {
      width: 132px;
      height: 10px;
      background: #483057;

      &.light {
        background: #cea0ba;
      }
    }

    .big-title {
      color: var(--unnamed-color-483057);
      text-align: left;
      font: normal normal bold 48px/40px Cormorant Garamond;
      letter-spacing: 0px;
      text-transform: uppercase;
      margin: 30px 0;
    }

    p {
      color: var(--unnamed-color-969696);
      text-align: left;
      font: normal normal normal 18px/30px Open Sans;
      letter-spacing: 0.27px;
    }
  }
}
