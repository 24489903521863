.Gallery {
  .top-image-block {
    position: relative;
    height: 499px;
    padding-top: 139px;

    .mini-line {
      background: #ffffff;
      width: 132px;
      height: 7px;
    }

    .container {
      z-index: 2;

      .site-name {
        font: normal normal bold 79px/90px Cormorant Garamond;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .layer,
    img {
      background: #3a2647;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &img {
        z-index: 0;
      }

      &.layer {
        z-index: 1;
        opacity: 0.44;
      }
    }
  }

  .page-photos {
    padding: 144px 0;

    .boxes {
      display: grid;
      grid-template-columns: auto auto auto auto;
      margin-bottom: 144px;

      .box {
        margin-bottom: 22px;
        margin-right: 1vw;
        width: 97%;
        position: relative;
        // max-width: 329px;
        // padding-top: 97%;
        // position: relative;

        &>span {
          width: 100%;
          height: 408px;
        }

        .skeleton {
          width: 100%;
          height: 408px;
          display: block;
          background-size: cover;
          background-color: #f1eded;
          transition: 1s;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;

          &.hide {
            background-color: #f1eded00;
            opacity: 0;
          }
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;

    text-align: center;
    font: normal normal normal 20px/20px Open Sans;
    letter-spacing: 2.4px;
    text-transform: uppercase;

    .page-item {
      &:first-child,
      &:last-child {
        .page-link {
          border: 1px solid white;
        }
      }

      &:last-child {
        .page-link {
          border-left: 1px solid #cea0ba;
        }
      }

      .page-link {
        border: 1px solid #cea0ba;
        color: #cea0ba;
        width: 50px;
        line-height: 36px;

        &.active {
          background-color: #cea0ba;
          color: white;
        }
      }
    }
  }
}



@media only screen and (max-width: 542px) {

  .Gallery {
    .Slider{
      width: 100%;

      .control-panel{
        span {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

}