header {
  padding: 14px 0px 10px;
  background: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  transition: 0.3s;

  .book-now {
    color: #483057;
  }


  .navbar-toggler {
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 35px;
    height: 35px;
    position: relative;
    right: 20px;

    &:focus {
      outline: none !important;
      box-shadow:none;
    }

    .line {
      display: inline-block;
      width: 100%;
      height: 0;
      border-bottom: 1px solid #483057;
      position: absolute;
      right: 0;
      transition: 1s;
    }

    .line:first-child {
      top: 7px;

      &.active {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-38deg);
      }
    }

    .line:nth-child(2) {
      top: 50%;
      width: 50%;

      &.active {
        display: none !important;
      }
    }

    .line:last-child {
      bottom: 7px;
      &.active {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(38deg);
      }
    }
  }

  &.active {
    background-color: #cea0ba;

    .right-section ul li a {
      color: white;
    }

    .logo .icon-logo {
      background-color: white;
    }
  }

  .logo .icon-logo {
    background-color: #483057;
  }

  .logo {
    width: 250px;
    min-width: 250px;
    height: 91px;
  }

  .right-section {
    ul {
      li {
        padding: 0 40px;

        &:last-child {
          padding-right: 0;
        }

        & .is-active {
          color: #cea0ba;
          pointer-events: none;
        }

        a {
          color: var(--unnamed-color-483057);
          font: normal normal normal 18px/18px Open Sans;
          letter-spacing: 0.27px;
          color: #483057;
        }

        & a:hover {
          color: #f0e3ea;
        }
      }
    }
  }

  .custom-dropdown-menu {
    position: relative;

    &:hover, &.active {
      .dropdown-menu-lists {
        width: 182px;
        height: 230px;
        opacity: 1;
      }
    }

    .dropdown-menu-lists {
      opacity: 0;
      width: 140px;
      height: 0;
      overflow: hidden;
      position: absolute;
      padding-top: 55px;
      bottom: 0;
      transform: translate(-50%, 100%);
      left: 50%;
      transition: 0.3s;

      .dropdown-menu-item {
        &:not(:last-child) {
          margin-bottom: 1px;
        }

        a {
          background: #cea0ba;
          width: 100%;
          display: inline-block;
          padding: 12px 10px;
          color: white;
          font: normal normal normal 16px/16px Open Sans;
          transition: 0.3s;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
