.icon-logo {
  background-color: #cea0ba;
  mask: url("/icons/logo_pink.svg") no-repeat center / contain;
  // -webkit-mask: url(https://img.icons8.com/stackoverflow) no-repeat center / contain;
}

.icon-venue {
  background-color: #cea0ba;
  mask: url("/icons/venue.png") no-repeat center / contain;
}

.icon-great-service {
  background-color: #cea0ba;
  mask: url("/icons/great-service.png") no-repeat center / contain;
}

.icon-fine-dining {
  background-color: #cea0ba;
  mask: url("/icons/fine-dining.png") no-repeat center / contain;
}

.icon-corporate-holiday-parties {
  background-color: #cea0ba;
  mask: url("/icons/corporate-event-page/corporate-holiday-parties.png")
    no-repeat center / contain;
}

.icon-fundraising-galas {
  background-color: #cea0ba;
  mask: url("/icons/corporate-event-page/fundraising-galas.png") no-repeat
    center / contain;
}

.icon-awards-banquets {
  background-color: #cea0ba;
  mask: url("/icons/corporate-event-page/awards-banquets.png") no-repeat center /
    contain;
}

.icon-retirement-parties {
  background-color: #cea0ba;
  mask: url("/icons/corporate-event-page/retirement-parties.png") no-repeat
    center / contain;
}

.icon-corporate-conferences {
  background-color: #cea0ba;
  mask: url("/icons/corporate-event-page/corporate-conferences.png") no-repeat
    center / contain;
}

.icon-guest {
  background-color: #cea0ba;
  mask: url("/icons/icone guest.png") no-repeat center / contain;
}

.icon-bufet-style {
  background-color: #cea0ba;
  mask: url("/icons/dining icone1.png") no-repeat center / contain;
}

.icon-family-style {
  background-color: #cea0ba;
  mask: url("/icons/dining icone2.png") no-repeat center / contain;
}

.icon-table-service {
  background-color: #cea0ba;
  mask: url("/icons/dining icone3.png") no-repeat center / contain;
}

.icon-hors-oeuvres {
  background-color: #cea0ba;
  mask: url("/icons/dining icone4.png") no-repeat center / contain;
}

.icon-take-out-catering {
  background-color: #cea0ba;
  mask: url("/icons/dining icone-1.png") no-repeat center / contain;
}

.icon-location {
  background-color: #000000;
  mask: url("/icons/location.png") no-repeat center / contain;
}

.icon-phone {
  background-color: #000000;
  mask: url("/icons/Phone.svg") no-repeat center / cover;
}

.icon-email {
  background-color: #000000;
  background-image: url("/icons/email.png") no-repeat center / contain;
}

.icon-fb {
  background-color: white;
  mask: url("/icons/fb.svg") no-repeat center / cover;
}

.icon-instagram {
  background-color: white;
  mask: url("/icons/insta.svg") no-repeat center / cover;
}

.icon-hill {
  background-color: white;
  mask: url("/icons/hill.svg") no-repeat center / cover;
}

.icon-left-arrow {
  background-color: #483057;
  mask: url("/icons/left-arrow.png") no-repeat center / contain;
}

.icon-right-arrow {
  background-color: #483057;
  transform: rotate(180deg);
  mask: url("/icons/left-arrow.png") no-repeat center / contain;
}

.icon-stop {
  background-color: #483057;
  mask: url("/icons/stop.png") no-repeat center / contain;
}

.icon-play {
  background-color: #483057;
  mask: url("/icons/play.png") no-repeat center / contain;
}

.icon-close {
  background-color: #483057;
  mask: url("/icons/close.png") no-repeat center / contain;
}



