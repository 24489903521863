footer {
  padding: 117px 0 73px;
  background: var(--unnamed-color-483057);
  font: normal normal normal 16px/16px Open Sans;
  letter-spacing: 0.24px;
  color: #ffffff;
  text-transform: uppercase;

  .icon-logo {
    background-color: #cea0ba;
    width: 270px;
    height: 135px;
  }

  .container {
    padding-bottom: 98px;
    border-bottom: 0.5px solid #ffffff;

    .row {
      min-height: 207px;
      & > div:not(:first-child) {
        border-left: 0.5px solid #ffffff;
        padding-left: 28px;
      }

      & > * {
        min-height: 140px;
        margin-bottom: 50px;
      }
    }
  }

  .address {
    font: normal normal normal 16px/35px Open Sans;
    letter-spacing: 0.24px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .phone {
    font: normal normal normal 36px/40px Open Sans;
    letter-spacing: 0.54px;
    color: #cea0ba;
    text-transform: uppercase;
  }

  .social-media {
    position: relative;
    left: -30px;
    
    i {
      width: 70px;
      height: 50px;
    }
  }
}
