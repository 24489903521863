.contact {
  .top-image-block {
    position: relative;
    height: 499px;
    padding-top: 139px;

    .mini-line {
      background: #ffffff;
      width: 132px;
      height: 7px;
    }

    .container {
      z-index: 2;

      .site-name {
        font: normal normal bold 79px/90px Cormorant Garamond;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .layer,
    img {
      background: #3a2647;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &img {
        z-index: 0;
      }

      &.layer {
        z-index: 1;
        opacity: 0.44;
      }
    }
  }

  .page-info {
    margin: 150px auto;

    input {
      height: 60px;
      border: 1px solid #c1c1c1;
      padding-left: 22px;
      margin-bottom: 10px;
    }

    .message-input {
      height: 186px;
      margin-top: 68px;

      textarea {
        width: 100%;
        height: 100%;
        resize: none;
      }
    }

    button {
      width: 250px;
      height: 50px;
      text-transform: uppercase;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      margin-top: 50px;
    }

    .boxes {
      justify-content: space-between;
      margin-top: 50px;

      .box {
        height: 231px;
        padding: 25px 59px 25px 33px;
        border: 1px solid black;
        margin-bottom: 10px;

        background: linear-gradient(white 50%, var(--unnamed-color-483057) 50%);
        background-size: 100% 200%;
        background-repeat: no-repeat;
        transition: background 0.2s;
        background-position: 100% 200%;

        &:hover {
          background-position: 100% 100%;
          color: white;
        }

        img {
          width: 58px;
          height: 58px;
          display: inline-block;
          margin-bottom: 20px;
        }

        p {
          margin: 0;
        }

        .title {
          font-size: 28px;
          font: normal normal bold 28px/62px Cormorant Garamond;
          color: #cea0ba;
          height: 38px;
          line-height: 34px;
        }

        .description {
          font-size: 28px;
          font: normal normal normal 20px/24px Open Sans;
          color: #878787;
        }
      }
    }
  }

  input,
  textarea,
  select {
    width: 100%;
    padding: 15px 16px;
    outline: 0;
    border: 0;
    background: #e5cad9;
    font: normal normal normal 18px/30px Open Sans;
    letter-spacing: 0.27px;
    color: grey;
  }

  .btn {
    color: white;
    border: 2px solid #ffffff;
  }

  .date {
    width: 275px;
    display: inline-block;
  }

  .date-container {
    display: flex;

    .select-box {
      width: 100%;
      margin-right: 13px;
    }

    .select-box {
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 542px) {
  .contact {
    .top-image-block {
      .container {
        .site-name {
          font: normal normal bold 65px/90px Cormorant Garamond;
        }
      }
    }

    .page-info {
      margin: auto;
      .selected-section-input {
        width: 100%;
        margin-bottom: 10px;
      }

      .date-container {
        width: 100%;
      }

      input {
        font: normal normal normal 14px/30px Open Sans;
      }

    }
  }
}
