.Dining {
  .top-image-block {
    position: relative;
    height: 499px;
    padding-top: 139px;

    .mini-line {
      background: #ffffff;
      width: 132px;
      height: 7px;
    }

    .container {
      z-index: 2;

      .site-name {
        font: normal normal bold 79px/90px Cormorant Garamond;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .layer,
    img {
      background: #3a2647;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &img {
        z-index: 0;
      }

      &.layer {
        z-index: 1;
        opacity: 0.44;
      }
    }
  }

  .page-info {
    color: var(--unnamed-color-969696);
    text-align: center;
    font: normal normal normal 20px/30px Open Sans;
    letter-spacing: 0.3px;
    padding: 140px 112px;
    border-radius: 50px;
    margin-top: -80px;
    z-index: 1;
    background-color: white;
  }

  .categories-block {
    position: relative;
    margin-bottom: 106px;

    .categories {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      .box {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 248px;
        min-width: 248px;
        height: 247px;
        font: normal normal normal 18px/18px Open Sans;
        letter-spacing: 0px;
        color: var(--unnamed-color-969696);
        text-align: center;
        position: relative;
        border-bottom: 1px solid #969696;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 15px;
        }

        i {
          width: 60px;
          height: 65px;
          margin-bottom: 17px;
        }

        &.active {
          border-bottom: 1px solid #cea0ba;
          background-color: #cea0ba;
          color: white;
          
          i {
            background-color: white;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 35px 43px 0 43px;
            border-color: #cea0ba transparent transparent transparent;
            transform: translateY(100%);
          }
        }
      }
    }
  }

  .event-info {
    margin-bottom: 140px;
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 0;
    }

    .content {
      position: relative;
      background: rgb(58 38 71 / 81%);
      padding: 52px 71px 52px 49px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: white;
      min-height: 493px;

      .mini-line {
        background-color: white;
      }

      .big-title {
        font: normal normal bold 48px/40px Cormorant Garamond;
        letter-spacing: 0px;
        text-transform: uppercase;
      }

      p {
        text-align: left;
        font: normal normal normal 18px/30px Open Sans;
        letter-spacing: 0.27px;
      }

      .btn:first-child {
        margin-right: 36px;
      }
    }
  }

  .enjoy-an-exceptional {
    margin-bottom: 121px;

    .mini-line {
      width: 132px;
      height: 10px;
      background: #483057;

      &.light {
        background: #cea0ba;
      }
    }

    .big-title {
      color: var(--unnamed-color-483057);
      text-align: left;
      font: normal normal bold 48px/40px Cormorant Garamond;
      letter-spacing: 0px;
      text-transform: uppercase;
      margin: 30px 0;
    }

    p {
      color: var(--unnamed-color-969696);
      text-align: left;
      font: normal normal normal 18px/30px Open Sans;
      letter-spacing: 0.27px;
    }
  }
}

@media only screen and (max-width: 542px) {

  .Dining {
    .top-image-block {
      .container {
        .site-name {
          font: normal normal bold 65px/90px Cormorant Garamond;
        }
      }
    }

    .categories-block {

      .full-block-image img {
        min-height: 345px;
      }

      .categories {
        margin-top: 22px;
        display: block;
        overflow-y: hidden;

        .box {
          border-bottom: 0;
        }
      } 
    }
  }

}