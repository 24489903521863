@media only screen and (max-width: 1200px) {
  .Gallery {
    .page-photos {
      .boxes {
        grid-template-columns: auto auto auto !important;
      }
    }
  }

  .block-image-and-info .row .block-info {
    width: auto;
  }
}

@media only screen and (max-width: 1500px) {
  .big-title,
  h2,
  h1 {
    font: normal normal bold 40px/44px Cormorant Garamond !important;
  }
}

@media only screen and (max-width: 542px) {
  body {
    padding-top: 91px;
  }

  #root {
    .Footer {
      text-align: center;
      .container {
        .row {
          .social-media {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
          div {
          
            justify-content: center;

          }
        }
      }
    }

    .Header {
      padding-bottom: 0;

      .container {
        padding: 0;
      }

      .navbar-toggler {
        position: relative;
        right: 20px;
      }

      .navbar {
        padding: 0;
      }

      .logo {
        width: 200px;
        min-width: 200px;
        height: 70px;
      }

      .book-now {
        display: inline-block !important;
        position: relative;
        right: 17px;
      }

      .right-section {
        text-align: left;
        background-color: #483057;
        width: 100%;
        margin-left: 0;
        padding: 30px 21px 75px;

        ul {
          display: inline-block !important;
          padding-left: 0;
          width: 100%;

          li {
            display: block;
            text-align: left;
            width: 100%;
            border-bottom: 1px solid #cea0ba;
            padding: 0;

            a {
              color: white !important;
              display: inline-block;
              width: 100%;
              line-height: 50px;
              text-align: left;

            }
          }
        }
      }
      .dropdown-menu-lists {
        position: unset;  
        transform: none;
        overflow:auto;
        padding: 0;
        width: 100%;
        
        .dropdown-menu-item {
          border-bottom: 1px solid #cea0ba;
          width: 100%;

          &:last-child {
            border-bottom: 0;
          }

          a {
            color: white !important;
            background-color: inherit;
            display: inline-block;
            width: 100%;
            line-height: 50px;
            text-align: left;

          }
        }
      }
    }

    .BookNow,
    .SocialEvents {
      display: none;
    }

    .Gallery {
      .top-image-block {
        height: 359px;
        margin-bottom: 30px;

        h1 {
          font: normal normal bold 44px/44px Cormorant Garamond !important;
        }
      }

      .page-photos {
        padding: 0;
        margin-bottom: 30px;
        .boxes {
          width: 340px;
          margin: auto;
          grid-template-columns: auto auto !important;
          .box {
            width: 160px;
            height: 160px;
            margin: 5px;

            & > span {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .Home {
      .top-image-block {
        height: 362px;
        padding-top: 82px;

        .welcome {
          font-size: 16px;
          margin-bottom: 0;
        }

        .site-name {
          font: normal normal bold 44px/44px Cormorant Garamond;
          letter-spacing: 0;
        }
      }

      .description-block {
        margin: 40px auto;
        font: normal normal normal 16px/30px Open Sans;
        width: 319px;
      }

      .event-items {
        margin-top: -80px;

        .items {
          display: block !important;
          width: 100%;

          .event-item {
            margin: 0;
            justify-content: center;
            display: flex;
            margin-bottom: 18px;

            .event-box {
              padding: 8px;
              display: inline-block !important;
              display: flex;
              justify-content: center;

              .content {
                width: 312px;
                height: 312px;
                margin: auto;
              }

              .info {
                padding: 10px;
                justify-content: center;
              }
            }
          }
        }
      }

      .menu-boxes {
        .d-flex {
          display: block !important;
          width: 100%;

          .item {
            margin-right: 0;

            .menu-box {
              width: 311px;
              height: 311px;
              margin: 13px auto;
            }
          }
        }
      }
    }
  }

  .page-info {
    border-radius: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .start-planning,
  .superior-catering {
    .content {
      padding: 64px 20px 90px !important;
    }
  }

  .page-info {
    padding: 110px 10px !important;
  }

  .block-image-and-info .row .block-info {
    padding: 10px;
  }
}
