.Birthday {  
  .top-image-block {
    position: relative;
    height: 499px;
    padding-top: 139px;

    .mini-line {
      background: #ffffff;
      width: 132px;
      height: 7px;
    }

    .container {
      z-index: 2;

      .site-name {
        font: normal normal bold 79px/90px Cormorant Garamond;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .layer,
    img {
      background: #3a2647;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &img {
        z-index: 0;
      }

      &.layer {
        z-index: 1;
        opacity: 0.44;
      }
    }
  }

  .page-info {
    color: var(--unnamed-color-969696);
    text-align: center;
    font: normal normal normal 20px/30px Open Sans;
    letter-spacing: 0.3px;
    padding: 140px 112px;
    border-radius: 50px;
    margin-top: -80px;
    z-index: 1;
    background-color: white;
  }

  .stunning-party-hall {
    margin-bottom: 144px;

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .big-title {
        color: var(--unnamed-color-483057);
        font: normal normal bold 48px/65px Cormorant Garamond;
        letter-spacing: 0px;
        text-transform: uppercase;
        margin-bottom: 35px;
      }

      .mini-line {
        width: 132px;
        height: 10px;
      }

      p {
        color: var(--unnamed-color-969696);
        font: normal normal normal 18px/30px Open Sans;
        letter-spacing: 0.27px;
      }

      .btn:first-child {
        margin-right: 36px;
      }
    }

    .img-block {
      text-align: right;
      img {
        width: 551px;
      }
    }
  }

  .superior-catering {
    background: var(--unnamed-color-483057) 0% 0% no-repeat padding-box;
    margin-bottom: 144px;

    .row {
      & > div {
        padding: 0;

        &.content {
          padding: 64px 115px 90px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .big-title {
            font: normal normal bold 62px/62px Cormorant Garamond;
            letter-spacing: 0px;
            color: #cea0ba;
            text-transform: uppercase;
          }

          .info {
            font: normal normal normal 18px/30px Open Sans;
            letter-spacing: 0.27px;
            color: #ffffff;
          }

          .phone {
            font: normal normal 600 48px/40px Open Sans;
            letter-spacing: 0px;
            color: #cea0ba;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .enjoy-an-exceptional {
    margin-bottom: 121px;

    .mini-line {
      width: 132px;
      height: 10px;
      background: #483057;
      
      &.light {
        background: #CEA0BA
      }
    }

    .big-title {
      color: var(--unnamed-color-483057);
      text-align: left;
      font: normal normal bold 48px/40px Cormorant Garamond;
      letter-spacing: 0px;
      text-transform: uppercase;
      margin: 30px 0;
    }

    p {
      color: var(--unnamed-color-969696);
      text-align: left;
      font: normal normal normal 18px/30px Open Sans;
      letter-spacing: 0.27px;
    }
  }
}


@media only screen and (max-width: 542px) {

  .Birthday {
    .top-image-block {
      .container {
        .site-name {
          font: normal normal bold 38px/60px Cormorant Garamond;
        }
      }
    }

    .stunning-party-hall {
      .info {
        padding: 15px;
        border: 0;

        &>div:last-child {
          display: block !important;

          .btn {
            width: 100%;
            margin-bottom: 5px;
            margin-right: 0;
          }
        }
      }
    }
  }

}