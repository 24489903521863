.BookNow {
  background-color: #cea0ba;
  position: fixed;
  z-index: 999999;
  padding: 20px 0 20px;
  display: flex;
  align-items: center;
  top: 138px;
  height: calc(100vh - 131px);
  width: 85vw;
  transform: translateX(-100%);
  transition: 0.3s;

  form {
    .row {
      margin-bottom: 24px;
    }
  }

  .mini-line {
    background-color: #483057;
  }

  // form {
  //   height: 200px;
  //   overflow: auto;
  // }

  h2 {
    font: normal normal bold 62px/62px Cormorant Garamond;
    letter-spacing: 0px;
    color: #483057;
    text-transform: uppercase;
  }

  p {
    font: normal normal normal 18px/30px Open Sans;
    color: #ffffff;
    letter-spacing: 0.27px;
  }

  input,
  textarea,
  select {
    width: 100%;
    padding: 15px 16px;
    outline: 0;
    border: 0;
    background: #e5cad9;
    font: normal normal normal 18px/30px Open Sans;
    letter-spacing: 0.27px;
    color: grey;
  }

  .btn {
    color: white;
    border: 2px solid #ffffff;
    padding: 16px 99px;
  }

  .date {
    width: 275px;
    display: inline-block;
  }

  .date-container {
    display: flex;

    .select-box {
      width: 100%;
      margin-right: 13px;
    }

    .select-box {
      display: inline-block;
    }
  }

  .btn-book {
    display: inline-block;
    position: absolute;
    top: 244px;
    right: -50px;
    height: 153px;
    width: 40px;
    background: rgb(206 160 186 / 60%) 0% 0% no-repeat padding-box;
    cursor: pointer;
    border-right: 3px solid #cea0ba;
    transition: 0.3s;

    &:hover {
      background: rgb(206 160 186 / 100%) 0% 0% no-repeat padding-box;

      .arrow {
        transform: translateY(-50%) scale(2);
      }
    }

    .title {
      display: inline-block;
      transform: rotate(-90deg);
      width: 153px;
      height: 40px;
      position: absolute;
      font: normal normal 600 18px/2px Open Sans;
      letter-spacing: 0.27px;
      color: #fafafa;
      left: -38px;
      top: 33px;
    }

    .arrow {
      color: #cea0ba;
      font-size: 25px;
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      font-family: monospace;
      transition: 0.3s;
    }
  }
}
