.action-buttons {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;

  span {
    display: inline-block;
    width: 80px;
    text-align: center;
    margin: 5px;
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
  }
}

.image {
  background-size: cover;
  background-position: center;
}